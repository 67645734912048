import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/dsaha1656.jpeg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              Hi, this is Dibyendu here; I’m a Full Stack Developer, Mobile Application Developer and DevOps expert with more than 3+ years of experience 
              completed many projects from scratch to production-ready. Check out my past work 
              <b className="purple"> <a target="_blank" href="https://github.com/dsaha1656">github.com/dsaha1656</a></b>
              <br/>
              I have experience in building scalable and high-quality applications and modern mobile apps.
              I also do any custom development, complex automation, or other tasks related to front-end, back-end, mobile development, and DevOps.
              <br/>
              If you want to build a high-quality and scalable application and need help doing so, contact me!
              <br/>
              I fell in love with programming and I have at least learnt something, I think… 🤷‍♂️
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" style={{borderRadius:"50%"}} alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/dsaha1656"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/dsaha1656"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/dsaha1656/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;

import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Dibyendu Saha</span>
            from <span className="purple">India.</span>
            <br />Professional👨🏻‍💻 Full Stack Developer💻 & DevOps Engineer🎯 as well as an Open Source Enthusiastic🤪 and automating🤖 my work with terminal, AI and magic.💫
            <br />
            <br />
            My Educational Qualification
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> <span className="purple">M.Tech. Computer Science & Engineering</span> <br/> Univerity of Kalyani, West Bengal
            </li>
            
            <br />
            <li className="about-activity">
              <ImPointRight /> <span className="purple">B.Tech. Computer Science & Engineering</span> <br/> Maulana Abul Kalam Azad University of Technology, West Bengal
            </li>
          </ul>

          <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            Learn more spread more~~~~{" "}
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import axios from "axios";
import pdf from "../../Assets/Resume-Dibyendu-Saha.pdf";
import { AiOutlineDownload } from "react-icons/ai";

function Resume() {
  const uri = "https://porfolio-backend.vercel.app/ranks/getRanks";
  const [spojRank, upadteSpojRank] = useState(0);
  const [hackerrank, upadteHackerank] = useState(0);
  const [sem, upadateSem] = useState(0);
  const [cgpa, upadteCgpa] = useState(0);

  useEffect(() => {
    axios
      .get(uri)
      .then((res) => {
        upadteSpojRank(res.data.message[0].spojRank);
        upadteHackerank(res.data.message[1].hackerrank);
        upadteCgpa(res.data.message[2].cgpa);
        upadateSem(res.data.message[3].sem);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
        <Row className="resume">
          <Col md={12} className="resume-left">
            <h3 className="resume-title">Experience</h3>
            <Resumecontent
              title="Full-stack Developer, ForeMedia · Full-time"
              date="July 2022 - Present"
              content={[
              ]}
            />
            <Resumecontent
              title="Software Developer, Foreshop Group · Freelance"
              date="June 2021 - Jun 2021 - Jul 2022 (1 year 2 months)"
              content={[
              ]}
            />
            <Resumecontent
              title="Project Engineer, Ardent Computech Pvt. Ltd. · Part-time"
              date="Feb 2020 - May 2021 (1 year 4 months)"
              content={[
              ]}
            />
            <Resumecontent
              title="Associate Developer, BetaCloud LLP · Part-time"
              date="Jun 2019 - Jan 2020 · (8 months)"
              content={[
              ]}
            />
            
            <h3 className="resume-title">Education</h3>
            <Resumecontent
              title="M.Tech. Computer Science & Engineering"
              date="Univerity of Kalyani, West Bengal (2020 - 2022)"
              content={[]}
            />
            <Resumecontent
              title="B.Tech. Computer Science & Engineering"
              date="Maulana Abul Kalam Azad University of Technology, West Bengal (2016 - 2020)"
              content={[]}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
          <AiOutlineDownload />&nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;
